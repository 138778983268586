import { Typography } from '@material-tailwind/react'
import { Properties as PropertiesType } from '@/types'
import Properties from './properties'

type Props = {
  properties: Array<PropertiesType.Properties>
  className?: string
  onChange?: (property: { [key: string]: string }) => void
}

export default function ProductProperties({
  properties,
  className = '',
  onChange,
}: Props) {
  if (!properties.length) {
    return
  }

  return (
    <div className={`flex flex-col gap-10 ${className}`}>
      {properties.map(
        (item, index) =>
          !!(item.values && item.values.length) && (
            <div key={index} className='flex flex-col gap-4'>
              <Typography
                variant='lead'
                className='text-left text-base font-normal text-primary'
              >
                {item.title}
                {':'}
              </Typography>

              <Properties
                type={item.name}
                options={item.values}
                onChange={
                  onChange
                    ? (option: string) => onChange({ [item.name]: option })
                    : undefined
                }
              />
            </div>
          )
      )}
    </div>
  )
}
