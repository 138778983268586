import { Typography } from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import Image from '@/components/Image'
import { ProductDetails } from '@/types/Components/ParentProductDetail'

type Props = {
  details: ProductDetails
  className?: string
}

export function ParentProductDetails({ details, className = '' }: Props) {
  const dictionary = useDictionary('parent_product_detail')

  return (
    <div className={`container overflow-hidden ${className}`}>
      <div className='mb-8 flex items-center'>
        <Typography
          as='p'
          variant='h2'
          className='flex-1 text-left text-3xl font-semibold text-primary underline underline-offset-2'
        >
          {dictionary('details_title')}
        </Typography>
      </div>

      <div className='flex w-full flex-col gap-8 md:flex-row'>
        <div>
          <Image
            className='h-[240px] w-full object-contain object-center'
            src={details.media?.imageUrl}
            width={290}
            height={240}
            alt={details.media?.bucket || 'Product Image'}
          />
        </div>

        {/* Properties Section */}
        <div className='flex-1'>
          {details.properties.map((item, index) => (
            <div className='mb-4' key={index}>
              <Typography
                as='p'
                variant='h4'
                className='mb-2 text-left text-xl font-semibold text-primary'
              >
                {item.groupTitle}
              </Typography>

              <table className='w-full table-auto overflow-x-auto'>
                <tbody>
                  {item.groupValues?.map((groupItem, groupIndex) => (
                    <tr
                      key={groupIndex}
                      className={`${
                        groupIndex % 2 === 0 ? 'bg-gray-100' : 'bg-gray-50'
                      }`}
                    >
                      <td className='px-4 py-2 text-left'>{groupItem.title}</td>
                      <td className='px-4 py-2 text-left'>
                        {groupItem.values?.map((value, valueIndex) => (
                          <span
                            key={valueIndex}
                            className='mr-0.5 inline-block'
                          >
                            {value.trim()}
                            {valueIndex < groupItem.values.length - 1 && ', '}
                          </span>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
