import { Button, Typography } from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import Image from '@/components/Image'
import { ProductOffers } from '@/types/Components/ParentProductDetail'
import { Badges } from '@/components/Badges'
import FakeLink from '@/components/FakeLink'
import { useEffect, useState } from 'react'
import {
  BuildHref,
  CurrentPageTrackingData,
  GetExternalLinkTrackingProductParams,
  GetExternalLinkTrackingUrlParams,
  TrackingExternalUrlSpecificData,
} from '@/components/NgTracker'

type Props = {
  offers: Array<ProductOffers>
  componentTrackingSpecificData?: TrackingExternalUrlSpecificData | undefined
  className?: string
}

export function ParentProductOffers({
  offers,
  componentTrackingSpecificData = {},
  className = '',
}: Props) {
  const d = useDictionary('parent_product_detail')
  const [visibleOffers, setVisibleOffers] = useState(10)
  const [trackingParams, setTrackingParams] = useState('')

  useEffect(() => {
    setTrackingParams(
      GetExternalLinkTrackingUrlParams(componentTrackingSpecificData)
    )
  }, [CurrentPageTrackingData])

  const handleShowMore = () => {
    setVisibleOffers((prev) => prev + 10)
  }

  return (
    <div className={`container ${className}`}>
      <div className='flex flex-col gap-4 py-8 md:flex-row md:items-center'>
        <Typography
          as='p'
          variant='h2'
          className='flex-1 text-left text-3xl font-semibold text-primary underline underline-offset-2'
        >
          {d('offers_title', { count: `${offers.length}` })}
        </Typography>
      </div>

      <div className='w-full'>
        <div className='w-full overflow-x-auto'>
          <table className='w-full border-none'>
            <thead className='hidden md:table-header-group'>
              <tr className='border-b border-gray-200 bg-transparent'>
                <th className='w-1/2 max-w-7xl px-4 py-2 text-left'>
                  Product Name
                </th>
                <th className='w-1/6 px-4 py-2 text-left'>Price</th>
                <th className='w-1/6 px-4 py-2 text-left'>Shop</th>
                <th className='w-1/6 px-4 py-2 text-left'></th>
              </tr>
            </thead>
            <tbody>
              {offers.slice(0, visibleOffers).map((offer, index) => (
                <tr
                  key={index}
                  className={`border-b border-gray-200 bg-transparent py-4 last:border-transparent`}
                >
                  <td className='flex flex-col gap-4 px-2 py-2 text-left md:flex-row md:items-center md:px-4'>
                    <div className='mb-2 flex flex-row gap-4 md:mb-0'>
                      <div className='relative hidden min-w-[80px] flex-shrink-0 md:block'>
                        {!!(offer.badges && offer.badges?.length) && (
                          <Badges badges={offer.badges} isIcon />
                        )}
                        <Image
                          className='h-[80px] w-[80px] object-contain object-center'
                          src={offer.media.imageUrl}
                          width={80}
                          height={80}
                          alt={offer.media.bucket}
                        />
                      </div>

                      <div className='flex flex-col gap-2 md:flex-row md:items-center'>
                        <Typography
                          variant='h3'
                          className='line-clamp-[2] overflow-hidden text-ellipsis text-left text-md font-normal text-primary'
                        >
                          <span className='line-clamp-[2] leading-normal'>
                            {offer.name}
                          </span>
                        </Typography>

                        <div className='flex flex-row items-center justify-between gap-2 md:hidden'>
                          <div className='flex flex-col'>
                            <div className='flex flex-col gap-1'>
                              {offer.hasSale && (
                                <Typography
                                  variant='lead'
                                  className='text-left text-base font-light line-through opacity-90'
                                >
                                  {offer.previousPrice}
                                </Typography>
                              )}
                              <Typography
                                variant='lead'
                                className={`text-left text-3xl font-bold ${
                                  offer.hasSale
                                    ? 'text-tertiary-red'
                                    : 'text-primary'
                                }`}
                              >
                                {offer.price}
                              </Typography>
                            </div>

                            <Typography
                              variant='lead'
                              className='text-left text-base font-light'
                            >
                              {offer.shop}
                            </Typography>
                          </div>
                          <div className='flex flex-col'>
                            <FakeLink
                              href={BuildHref(
                                offer?.link,
                                trackingParams,
                                GetExternalLinkTrackingProductParams(
                                  offer?.id,
                                  offer?.shopId
                                )
                              )}
                              target='_blank'
                              className='flex items-center justify-center border border-secondary p-2 text-center leading-[150%] text-primary'
                            >
                              {d('to_shop_btn')}
                            </FakeLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td className='hidden px-4 py-2 md:table-cell'>
                    <div className='flex flex-col gap-1'>
                      {offer.hasSale && (
                        <Typography
                          variant='lead'
                          className='text-left text-base font-light line-through opacity-90'
                        >
                          {offer.previousPrice}
                        </Typography>
                      )}
                      <Typography
                        variant='lead'
                        className={`text-left text-3xl font-bold ${
                          offer.hasSale ? 'text-tertiary-red' : 'text-primary'
                        }`}
                      >
                        {offer.price}
                      </Typography>
                    </div>
                  </td>
                  <td className='hidden px-4 py-2 md:table-cell'>
                    <Typography
                      variant='lead'
                      className='text-left text-base font-light'
                    >
                      {offer.shop}
                    </Typography>
                  </td>
                  <td className='hidden px-4 py-2 md:table-cell'>
                    <FakeLink
                      href={BuildHref(
                        offer?.link,
                        trackingParams,
                        GetExternalLinkTrackingProductParams(
                          offer?.id,
                          offer?.shopId
                        )
                      )}
                      target='_blank'
                      className='flex items-center justify-center border border-secondary p-2 text-center leading-[150%] text-primary'
                    >
                      {d('to_shop_btn')}
                    </FakeLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {visibleOffers < offers.length && (
          <div className='mt-4 flex justify-center'>
            <Button
              onClick={handleShowMore}
              variant='outlined'
              className='border border-primary px-4 py-2 text-primary'
            >
              {d('show_more_offers')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
