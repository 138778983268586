import { Typography } from '@material-tailwind/react'
import { useDictionary, usePage } from '@/providers'
import Image from '@/components/Image'
import { ProductVariants } from '@/types/Components/ParentProductDetail'
import FakeLink from '@/components/FakeLink'
import Link from '@/components/Link'
import Slider, { breakpoints, SliderItem } from '@/components/Slider'
import Icon from '@/components/Icon'

type Props = {
  variants: Array<ProductVariants>
  className?: string
  resetSelectedOptions?: () => void
  activeId?: number
}

export function ParentProductVariantsSlider({
  variants,
  resetSelectedOptions,
  activeId,
  className = '',
}: Props) {
  const d = useDictionary('parent_product_detail')
  const { pages } = usePage()

  const sliderProps = {
    slidesPerView: 4.5,
    spaceBetween: 10,
    centerInsufficientSlides: true,
    grabCursor: true,
    breakpoints: {
      [breakpoints.sm]: {
        slidesPerView: 6.5,
      },
      [breakpoints.md]: {
        slidesPerView: 7.5,
      },
      [breakpoints.lg]: {
        slidesPerView: 7.5,
      },
      [breakpoints.xl]: {
        slidesPerView: 7.5,
      },
      [breakpoints.xxl]: {
        slidesPerView: 9.5,
      },
    },
  }

  if (!variants || !variants.length) {
    return null
  }

  return (
    <div className={`relative w-full ${className}`}>
      <Typography
        variant='lead'
        className='mb-4 text-left text-base font-normal text-primary'
      >
        {d('variants_title')}
        {':'}
      </Typography>

      <div className='relative w-full overflow-hidden'>
        <Slider
          className='!overflow-visible'
          imageEl='.variant-slider-image'
          navNextClassName='right-0'
          {...sliderProps}
        >
          <SliderItem>
            <div
              onClick={resetSelectedOptions}
              className='variant-slider-image cursor-pointer border border-light-gray-color bg-white p-1'
            >
              <Typography
                variant='lead'
                className='flex h-[40px] items-center justify-center text-left text-base font-normal uppercase text-primary'
              >
                {d('variants_all_title')}
              </Typography>
            </div>
          </SliderItem>

          {variants?.map((item, index) => {
            const isActive = item.nexgeonProductId === activeId
            const CommonProps = {
              className: `overflow-hidden variant-slider-image block relative border bg-white p-1 ${isActive ? 'border-black' : 'border-light-gray-color'}`,
              href: `/${pages.parentProduct}/${item.parentProductSlug}/${item.nexgeonProductId}`,
              title: item.minPrice,
            }

            const Wrapper = item.indexable ? Link : FakeLink

            return (
              <SliderItem key={index}>
                <Wrapper key={index} {...CommonProps}>
                  <Image
                    className='h-[40px] w-full object-contain object-center'
                    src={item.media.imageUrl}
                    width={60}
                    height={40}
                    alt={item.media.bucket}
                  />
                  {!!isActive && (
                    <span className='absolute left-0 top-0 flex h-[35px] w-[35px] -translate-x-1/2 -translate-y-1/2 rotate-45 bg-black text-sm'>
                      <Icon
                        className='absolute bottom-[12px] right-[1.5px] z-10 block -rotate-45 font-bold text-white'
                        icon='check'
                        size='11px'
                      />
                    </span>
                  )}
                  {item.disabled && (
                    <div className='absolute bottom-0 left-0 right-0 top-0 z-10 justify-center overflow-hidden bg-white/80'>
                      <div className='absolute inset-0 h-full w-full'>
                        <div className='absolute left-1/2 top-1/2 h-[200%] w-[2px] origin-center -translate-x-1/2 -translate-y-1/2 rotate-[55deg] transform bg-red-500'></div>
                      </div>
                    </div>
                  )}
                </Wrapper>
              </SliderItem>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}
