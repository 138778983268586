import { ParentProductDetailComponent } from '@/types'
import ParentProductDetail from '@/containers/sections/parent-product-detail'

export default function generate({
  component,
}: {
  component: ParentProductDetailComponent.Component
}) {
  return (
    <ParentProductDetail
      breadcrumb={component.config.breadcrumb}
      product={component.config.mainProduct}
      variants={component.config.nexgeonProducts}
      offers={component.config.productOffers}
      details={component.config.productDetails}
      isVariantPage={component.config.isNexgeonProductPage}
    />
  )
}
