import { useMemo } from 'react'
import { ParentProductDetailComponent, Properties } from '@/types'

export function useFilterProperties(
  properties: Array<Properties.Properties>,
  offers: Array<ParentProductDetailComponent.ProductOffers>,
  activeOptions: { [key: string]: string }
) {
  return useMemo(() => {
    return computeDisabledOptions(properties, offers, activeOptions)
  }, [properties, offers, activeOptions])
}

export function computeDisabledOptions(
  properties: Array<Properties.Properties>,
  offers: Array<ParentProductDetailComponent.ProductOffers>,
  activeOptions: { [key: string]: string }
): Array<Properties.Properties> {
  const relatedProperties = generateRelatedProperties(properties, offers)

  properties.forEach((property) => {
    const propertyName = property.name

    property.values.forEach((option) => {
      option.isActive = activeOptions[propertyName] === option.value
      option.disabled = shouldDisableOption(
        propertyName,
        option,
        activeOptions,
        offers,
        relatedProperties
      )
    })
  })

  return properties
}

function shouldDisableOption(
  propertyName: string,
  option: { value: string; slug: string },
  activeOptions: { [key: string]: string },
  offers: Array<ParentProductDetailComponent.ProductOffers>,
  relatedProperties: { [key: string]: string }
): boolean {
  const relatedProperty = relatedProperties[propertyName]

  if (relatedProperty && activeOptions[relatedProperty]) {
    // Check availability based on the related property
    const isOptionAvailable = offers.some(
      (offer) =>
        offer[relatedProperty]?.some(
          (relatedOption: any) =>
            relatedOption.value === activeOptions[relatedProperty]
        ) &&
        offer[propertyName]?.some(
          (propertyOption: any) => propertyOption.value === option.value
        )
    )

    return !isOptionAvailable // Disable option if not available with the related property
  }

  return false // No need to disable if there are no related properties
}

function generateRelatedProperties(
  properties: Array<Properties.Properties>,
  offers: Array<ParentProductDetailComponent.ProductOffers>
): { [key: string]: string } {
  const relatedProperties: { [key: string]: string } = {}

  properties.forEach((property) => {
    const propertyName = property.name

    // Find potential related properties by checking cross-dependencies in offers
    const relatedProperty = properties.find((otherProperty) => {
      if (otherProperty.name === propertyName) return false // Skip self-comparison

      return offers.some(
        (offer) =>
          offer[otherProperty.name]?.length && offer[propertyName]?.length // Ensure other property exists in the offer // Ensure the current property exists in the offer
      )
    })

    if (relatedProperty) {
      relatedProperties[propertyName] = relatedProperty.name
    }
  })

  return relatedProperties
}
