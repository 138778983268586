'use client'

import React, { useState } from 'react'
import Cookies from 'js-cookie'
import { Breadcrumb, ParentProductDetailComponent } from '@/types'
import Breadcrumbs from '@/components/Breadcrumbs'
import {
  ParentImageGallery,
  ParentProductInfo,
  ParentProductVariants,
  ParentProductOffers,
  ParentProductDetails,
  useFilteredAndSortedVariants,
  useFilteredOffers,
  filterAndSortVariants,
} from '@/components/ParentProductDetail'
import {
  GetComponentTrackingSpecificData,
  NG_WAS_PRODUCT_DETAIL,
} from '@/components/NgTracker'
import { useFilterProperties } from '@/components/ParentProductDetail/utils/filterProperties'
import { usePage } from '@/providers'
import { Typography } from '@material-tailwind/react'

type Props = {
  breadcrumb: Array<Breadcrumb>
  product: ParentProductDetailComponent.ProductDetail
  variants: Array<ParentProductDetailComponent.ProductVariants>
  offers: Array<ParentProductDetailComponent.ProductOffers>
  details: ParentProductDetailComponent.ProductDetails
  isVariantPage: boolean
}

export default function ProductDetail({
  breadcrumb,
  product,
  variants,
  offers,
  details,
  isVariantPage,
}: Props) {
  const { pages, cookies } = usePage()
  const cookieKey = `activeOptions_${product.parentProductId || product.id}`

  const [activeOptions, setActiveOptions] = useState<{ [key: string]: string }>(
    cookies[cookieKey] || {}
  )

  const onPropertyChange = (property: { [key: string]: string }) => {
    const updatedOptions = { ...activeOptions }
    const key = Object.keys(property)[0]
    const value = property[key]

    if (updatedOptions[key]) {
      if (updatedOptions[key] === value) {
        delete updatedOptions[key]
      } else {
        updatedOptions[key] = value
      }
    } else {
      updatedOptions[key] = value
    }

    const { filteredVariants, notDisabledVariantsCount } =
      filterAndSortVariants(variants, updatedOptions)

    setActiveOptions(updatedOptions)

    if (notDisabledVariantsCount === 1) {
      const notDisabledVariant = filteredVariants.find(
        (variant) => !variant.disabled
      )

      if (notDisabledVariant) {
        window.open(
          `/${pages.parentProduct}/${notDisabledVariant.parentProductSlug}/${notDisabledVariant.nexgeonProductId}`,
          '_self' as string
        )
      }
    } else if (isVariantPage) {
      window.open(
        `/${pages.parentProduct}/${product.parentProductSlug}`,
        '_self' as string
      )
    }

    Cookies.set(cookieKey, JSON.stringify(updatedOptions), { expires: 1 })
  }

  const onResetSelectedOptions = () => {
    window.open(
      `/${pages.parentProduct}/${product.parentProductSlug}`,
      '_self' as string
    )
    Cookies.remove(cookieKey)
  }

  const { filteredVariants } = useFilteredAndSortedVariants(
    variants,
    activeOptions
  )

  const filteredOffers = useFilteredOffers(offers, activeOptions)

  const filteredProperties = useFilterProperties(
    product?.properties || [],
    filteredOffers,
    activeOptions
  )

  return (
    <section className='overflow-hidden'>
      <div className='container'>
        <Breadcrumbs breadcrumbs={breadcrumb} />

        <div className='flex flex-col gap-4 lg:flex-row lg:gap-8'>
          <div className={`lg:basis-1/3`}>
            <Typography
              as='p'
              variant='h1'
              className='text-left text-2xl font-normal text-primary lg:hidden'
            >
              <span>{product.name}</span>
              {!!(
                product?.generated_color && product?.generated_color.length
              ) && (
                <span className='ml-1 text-md'>
                  {product?.generated_color.map((color, idx) => (
                    <React.Fragment key={idx}>
                      {color.value.trim()}
                      {idx < (product.generated_color?.length || 0) - 1 &&
                        ' / '}
                    </React.Fragment>
                  ))}
                </span>
              )}
            </Typography>
            <ParentImageGallery
              className={`${!isVariantPage ? 'hidden lg:block' : ''} `}
              medias={product.medias}
              alt={product.name}
            />
          </div>
          <div className='flex flex-col lg:w-8/12 lg:basis-8/12'>
            <ParentProductInfo
              name={product.name}
              properties={filteredProperties}
              productOverview={product.productOverview}
              onPropertyChange={onPropertyChange}
              variants={isVariantPage ? filteredVariants : []}
              resetSelectedOptions={onResetSelectedOptions}
              colors={isVariantPage ? product?.generated_color : []}
              activeId={product?.nexgeonProductId}
            />
          </div>
        </div>
      </div>

      {!isVariantPage && (
        <ParentProductVariants className='mb-4' variants={filteredVariants} />
      )}

      <ParentProductOffers
        className='mb-8'
        offers={filteredOffers}
        componentTrackingSpecificData={GetComponentTrackingSpecificData(
          '',
          NG_WAS_PRODUCT_DETAIL
        )}
      />

      <ParentProductDetails className='mb-4' details={details} />
    </section>
  )
}
