import { ParentProductDetailComponent, Properties } from '@/types'
import { Typography } from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import ProductProperties from '@/components/ProductProperties'
import { ParentProductVariantsSlider } from '@/components/ParentProductDetail/ParentProductVariantsSlider'
import React from 'react'

type Props = {
  name: string
  properties: Array<Properties.Properties>
  productOverview: string[]
  variants: Array<ParentProductDetailComponent.ProductVariants>
  onPropertyChange?: (property: { [key: string]: string }) => void
  resetSelectedOptions?: () => void
  colors?: Array<Properties.PropertyOptions>
  activeId?: number
}

export function ParentProductInfo({
  name,
  properties,
  productOverview,
  onPropertyChange,
  variants,
  resetSelectedOptions,
  activeId,
  colors = [],
}: Props) {
  const d = useDictionary('parent_product_detail')

  return (
    <div className='flex flex-col'>
      <Typography
        as='p'
        variant='h1'
        className='mb-4 hidden text-left text-2xl font-normal text-primary lg:block'
      >
        <span>{name}</span>
        {!!(colors && colors.length) && (
          <span className='ml-1 text-md'>
            {colors.map((color, idx) => (
              <React.Fragment key={idx}>
                {color.value.trim()}
                {idx < colors.length - 1 && ' / '}
              </React.Fragment>
            ))}
          </span>
        )}
      </Typography>

      {!!(productOverview && productOverview.length) && (
        <Typography
          as='p'
          variant='h3'
          className='text-left text-md text-primary'
        >
          <span className='mb-0.5 inline-block lg:mb-0 lg:mr-1.5'>
            {d('properties_list')}
          </span>
          {productOverview.map((item, idx) => (
            <span key={idx} className='mr-0.5 inline-block'>
              {item.trim()}
              {idx < productOverview.length - 1 && ', '}
            </span>
          ))}
        </Typography>
      )}

      <ParentProductVariantsSlider
        variants={variants}
        resetSelectedOptions={resetSelectedOptions}
        activeId={activeId}
      />

      <ProductProperties
        properties={properties}
        onChange={onPropertyChange}
        className='my-6'
      />
    </div>
  )
}
