import { Badge } from '@/types'

import BadgeItem from './bagdeItem'
import BadgeIconItem from './badgeIconItem'

type Props = {
  badges: Array<Badge.Badge>
  isIcon?: boolean
  className?: string
}

export function Badges({ badges, isIcon = false, className = '' }: Props) {
  return (
    <div
      className={`absolute ${isIcon ? 'left-0 top-0' : 'bottom-1 left-1'} flex flex-col gap-2 ${className}`}
    >
      {badges.map((badge, index) =>
        isIcon ? (
          <BadgeIconItem key={index} type={badge.type} />
        ) : (
          <BadgeItem key={index} type={badge.type} />
        )
      )}
    </div>
  )
}
