import { Properties as PropertiesType } from '@/types'
import Color from './color'
import Size from './size'

type Props = {
  options: Array<PropertiesType.PropertyOptions>
  type: PropertiesType.Types
  onChange?: (property: string) => void
}

export default function Properties({ options, type, onChange }: Props) {
  switch (type) {
    case PropertiesType.Types.COLOR:
      return <Color options={options} onChange={onChange} />
    case PropertiesType.Types.SIZE:
      return <Size options={options} onChange={onChange} />
    default:
      return null
  }
}
